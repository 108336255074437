<template>
    <div class="mx-3">
        <h2 class="px-5 py-5">{{ $t('Taxes Report') }}</h2>
        <v-row class="px-10 py-1">
            <v-col md="3">
                <v-text-field :dark="$store.state.isDarkMode" :label="$t('hr.time.from-date')" type="date" v-model="filters.from">
                </v-text-field>
            </v-col>
            <v-col md="3">
                <v-text-field :dark="$store.state.isDarkMode" :label="$t('hr.time.to-date')" type="date" v-model="filters.to">
                </v-text-field>
            </v-col>
            <v-col md="3">
                <v-select v-model="filters.source" :items="sourceType" :label="$t('Source')" item-text="name"
                    item-value="value"></v-select>
            </v-col>
            <v-col md="3">
                <v-select :items="itemsInvoece" :label="$t('Invoice Name')" multiple item-text="name" item-value="id"
                    v-model="filters.invoice_name"></v-select>
            </v-col>
            <v-col md="3">
                <v-select :items="itemsVoucher" :label="$t('Document Name')" multiple item-text="name" item-value="id"
                    v-model="filters.voucher_name"></v-select>
            </v-col>
            <v-col md="3">
                <v-autocomplete :label="$t('Tax Type')"   color="#757575" item-text="code" item-value="id"
                    :items="itemsTaxType" v-model="filters.tax_type_id"></v-autocomplete>
            </v-col>
            <v-col md="3">
                <v-autocomplete :label="$t('Tax Code')"   color="#757575" item-text="code" item-value="id"
                    :items="taxes" v-model="filters.tax_id"></v-autocomplete>
            </v-col>
            <v-col md="3"></v-col>
            <v-col md="3" class="mb-3 py-0">
                <v-btn color="green" dark block @click="getAll(1)">{{ $t('search') }}</v-btn>
            </v-col>
            <v-col md="3" class="mb-3 py-0">
                <v-btn color="red" dark block @click="clearFilter">{{ $t('Delete Search') }}</v-btn>
            </v-col>
            <v-col md="3" class="mb-3 py-0" v-if="returnAbility('tax:excel')">
                <ExportExcel end-point="/accounting/tax/export" :params="filters" />
            </v-col>
        </v-row>
        <v-data-table :dark="$store.state.isDarkMode" :headers="headers" :loading="loading" :items="tableItems"
            class="elevation-1 px-10" hide-default-footer :items-per-page="10">
            <template v-slot:item.actions="{ item }">
          <!-- v-if="returnAbility('entry:show') && (!item.entry.operate) && (item.entry.owner_type != null)" -->
          <v-btn v-if="returnAbility('tax:show')" @click="goTodestination(item.source,item.owner_id,item.owner_type_id)" color="green" icon>
          <v-icon class="ml-1">mdi-eye</v-icon></v-btn
        >
        </template>
        </v-data-table>
        <v-pagination v-if="!loading && tableItems.length" @input="getAll" v-model="page"
            :length="Math.ceil(total / 10)"></v-pagination>
        <div style="height: 20px;">
        </div>
    </div>
</template>

<script>
import axios from "axios";
import i18n from '@/i18n';
import ExportExcel from "../../components/core/ExportExcel.vue";
export default {
    components: {
        ExportExcel,
    },
    data: () => ({
        loading: false,
        page: 1,
        total: 1,
        headers: [
            { text: i18n.t('date'), value: "date" },
            { text: i18n.t("serial number"), value: "serial" },
            { text: i18n.t('Document'), value: "document" },
            { text: i18n.t('Account'), value: "account_name" },
            { text: i18n.t('Tax Registration Number'), value: "tax_registration_number" },
            { text: i18n.t('Tax Code'), value: "tax_code" },
            { text: i18n.t('Tax Percent'), value: "tax_percent" },
            { text: i18n.t('Tax Value'), value: "tax_value" },
            { text: i18n.t('description'), value: "desc" },
            { text: "", value: "actions" },
        ],
        items: [],
        sourceType: [
            { name: 'invoice', value: 'INVOICE' }, { name: 'document', value: 'VOUCHER' },
        ],
        filters: {
            from: null,
            to: null,
            tax_id: null,
            invoice_name: [],
            voucher_name: [],
            tax_type_id: null,
            source: null,
        },
        taxes: [],
        itemsInvoece: [],
        itemsVoucher: [],
        itemsTaxType: [],
    }),
    methods: {
        goTodestination(dis,id,type){
      if(dis=="Voucher"){
        this.$router.push(`/edit-document/${id}?document_id=${type}`);
      }
      if(dis=="Invoice"){
        this.$router.push(`/edit-invoice/${id}?invoice_id=${type}`);
      }
    },
        clearFilter() {
            this.filters.from = undefined;
            this.filters.to = undefined;
            this.filters.tax_id = undefined;
            this.filters.invoice_name = [];
            this.filters.voucher_name = [];
            this.filters.tax_type_id = undefined;
            this.filters.source = undefined;
            this.page = 1;
            this.getAll();
        },
        async getAll(page) {
            try {
                if (!page) this.page = 1;
                const filterTemp = {};
                Object.keys(this.filters).forEach((key) => {
                    if (this.filters[key]) filterTemp[key] = this.filters[key];
                });
                this.loading = true;
                const res = await axios.get("/accounting/tax/report", {
                    params: {
                        size: 10,
                        page: this.page,
                        ...filterTemp,
                    },
                });
                const arr = Array.from(res.data.data)
                if (Array.isArray(arr)) {
                    console.log(arr);
                    this.tableItems = arr.map((el) => {
                        return { ...el, };
                    });
                }
                console.log(res.data.data);
                this.total = res.data.total;
            } finally {
                this.loading = false;
            }
        },
    },
    async created() {
        this.getAll();
        const res = await axios.get(`/accounting/tax?paginated=false`);
        this.taxes = res.data.data;
        const response = await axios.get("/inventories/invoice-types/index?paginated=false");
        this.itemsInvoece = response.data.data;
        const result = await axios.get("/accounting/voucher-type?paginated=false");
        this.itemsVoucher = result.data.data;
        const taxTypeRes = await axios.get("/accounting/tax-type?paginated=false");
        this.itemsTaxType = taxTypeRes.data.data;
    },
};
</script>
  
<style></style>
  